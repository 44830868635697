@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.podcast {
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 0;
  min-width: 0;
  padding: calc($padding / 2) 0;
  border-radius: $border-radius;
  transition: $animation-card;

  &:hover,
  &:focus {
    // background-color: $grey;
    // box-shadow: $shadow-edges;

    .date {
      opacity: $opacity--semi;
    }

    .glow {
      opacity: $opacity--shown;
      width: 90%;
      height: 90%;
    }
  }

  // &:active {
  //   outline: none;
  //   opacity: 0.33 !important;
  //   @include transform(translateY(2px));
  // }

  // @include breakpoint('min-width', $breakpoint-medium) {
    // padding: $foundation;
    // width: calc(100% / 3);
  // }

  @include breakpoint('min-width', $breakpoint-large) {
    flex-direction: column;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: calc(100% / 4);
  }

  // @include breakpoint('min-width', $breakpoint-xxlarge) {
  //   width: calc(100% / 5);
  // }

  &.fullscreen {
    @include breakpoint('min-width', $breakpoint-large) {
      width: calc(100% / 4);
    }

    @include breakpoint('min-width', $breakpoint-xxlarge) {
      width: calc(100% / 5);
    }

    @include breakpoint('min-width', $breakpoint-xxxlarge) {
      width: calc(100% / 6);
    }

    @include breakpoint('min-width', $breakpoint-xxxxlarge) {
      width: calc(100% / 8);
    }
  }
}

.glow {
  opacity: $opacity--hidden;
  transition: $animation;
  background-color: $grey;
  z-index: -1;
  width: 50%;
  height: 50%;
  filter: blur(25px);
  @include center();
  @include border-radius(1000px);
}

.image {
  // background-color: $grey;
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden;
  min-width: 54px;
  max-width: 54px;
  border-radius: calc($border-radius / 2);
  aspect-ratio: $one-one;
  margin-bottom: calc($padding / 4 * -1);

  @include breakpoint('min-width', $breakpoint-medium) {
    max-width: 100px;
  }

  @include breakpoint('min-width', $breakpoint-large) {
    background-image: url('../../../public/1x1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 7%;
    min-width: 100%;
    max-width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.channel {
  justify-content: flex-start;
}

.content {
  width: 100%;
  padding-bottom: $padding;
  padding-left: calc($padding / 2);

  @include breakpoint('max-width', $breakpoint-medium) {
    width: calc(100% - $padding * 2);
    padding-left: $padding;
    padding-bottom: calc($padding / 4);
  }

  @include breakpoint('min-width', $breakpoint-large) {
    padding: calc($padding / 4) 4.5%;
  }

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;
  }
}

.description {
  font-size: 0.9rem;
  line-height: $line-height--small;
  opacity: 0.75;
  text-wrap: balance;

  p {
    margin: 0;
    @include line-clamp(3);

    @include breakpoint('min-width', $breakpoint-medium) {
      @include line-clamp(4);
    }

    @include breakpoint('min-width', $breakpoint-large) {
      @include line-clamp(5);
    }
  }
}

.duration {
  font-size: 0.75rem;
  opacity: $opacity--half;
  transition: $animation;
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    margin-left: calc($padding / 2);
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      fill: $grey--hover;
      margin-right: $title-padding;
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}