@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.item {
  @extend .channel;
  height: 46px;
  display: inline-flex;
  box-shadow: $shadow-edges;
  background-color: rgb(0, 0, 0);
  color: $poe-color__unique;
  border: 1px solid $poe-color__unique;
  color: $poe-color__red;
  border: 1px solid $poe-color__red;
  background: white;
  position: fixed;
  overflow: visible;
  z-index: 99;
  font-size: 30px;
  opacity: 1;
  // height: auto;

  img {
    margin-right: calc($padding / 4);
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 50%;
    height: 20rem;
    width: 5px;
    background: transparent;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 25rem solid white;
    transform: translate(-50%, 0);
    z-index: -1;
    filter: blur(7.5px);
  }

  &:hover,
  &:focus {
    color: $poe-color__red;
    border: 1px solid $poe-color__red;
    background: white;
    @include box-shadow(0 0 50px -4px white);
  }

  &Disconnected {
    width: 100%;
    position: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);

    img {
      width: 606px;
      height: 180px;
      @include center();
    }
  }
}