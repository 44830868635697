@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.outer {
  width: 100%;
  position: relative;

  &:hover,
  &:focus {
    .close {
      opacity: $opacity--shown;
    }

    .glow {
      opacity: $opacity--shown;
      width: 90%;
      height: 90%;
    }
  }

  &.fullscreen {
    display: flex;
    align-items: stretch;
    // height: 100%;

    @include breakpoint('min-width', $breakpoint-large) {
      width: calc(100% / 4);

      .metaInner {
        // width: auto;
        width: 100%;
        max-width: auto;
      }

      .article {
        display: block;
        // height: 100%;
      }

      .image {
        max-width: 100%;
      }

      .content {
        padding-left: 0;
        padding-top: calc($padding / 3);

        &Inner {
          padding-top: $title-padding;
        }
      }

      .title {
        margin-bottom: $title-padding;
        @include line-clamp(3);
      }

      .description p {
        @include line-clamp(4);
      }

    }

    @include breakpoint('min-width', $breakpoint-xxlarge) {
      width: calc(100% / 5);
    }

    @include breakpoint('min-width', $breakpoint-xxxlarge) {
      width: calc(100% / 6);
    }

    @include breakpoint('min-width', $breakpoint-xxxxlarge) {
      width: calc(100% / 8);
    }
  }
}

.article {
  display: block;
  min-height: 0;
  min-width: 0;
  // padding: calc($foundation / 1.25);
  width: 100%;
  border-radius: $border-radius;
  color: $white;
  text-decoration: none;
  transition: $animation;

  &:hover,
  &:focus {
    // background-color: $grey;
    // box-shadow: $shadow-edges--shadow-only;

    .tags {
      span {
        opacity: $opacity--shown;
      }
    }

    .duration {
      opacity: $opacity--shown;
    }

    .date {
      opacity: $opacity--semi;
    }
  }

  @include breakpoint('max-width', $breakpoint-medium) {
    margin-bottom: $padding;
  }

  @include breakpoint('min-width', $breakpoint-small) {
    padding: $foundation;
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    display: flex;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    // flex-direction: column;
    flex-direction: row;
  }
}

.image {
  // background-color: $grey;
  position: relative;
  height: auto;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  // border-radius: calc($border-radius / 2);
  aspect-ratio: $sixteen-nine;
  margin-bottom: calc($padding / 4 * -1);

  @include breakpoint('max-width', $breakpoint-large) {
    margin-bottom: calc($padding / 2);
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    min-width: 170px;
    max-width: 170px;
    padding: 10px 18px;
    background-image: url('../../../public/mainlivestream-frame_1080p.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // aspect-ratio: $four-three;
  }

  @include breakpoint('min-width', $breakpoint-large) {
    min-width: 200px;
    max-width: 200px;
    // aspect-ratio: $sixteen-nine;
  }

  @include breakpoint('min-width', $breakpoint-xxxxlarge) {
    min-width: 240px;
    max-width: 240px;
    padding: 11px 20px;
    // aspect-ratio: $sixteen-nine;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  width: 100%;

  @include breakpoint('min-width', $breakpoint-medium) {
    padding-left: calc($padding / 2);
  }

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;
  }
}

.description {
  font-size: 0.9rem;
  line-height: $line-height--small;
  opacity: 0.75;
  text-wrap: balance;

  p {
    margin: 0;
    @include line-clamp(2);
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // font-size: 0.85rem;

  @include breakpoint('min-width', $breakpoint-medium) {
    justify-content: flex-start;
  }

  span {
    // margin-right: $padding;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    // background-color: $grey;
    min-width: 24px;
    max-width: 24px;
    // border-radius: 24px;
    overflow: hidden;
    margin-right: calc($padding / 4);
  }

  &Inner {
    display: flex;
    align-items: center;
    max-width: 75%;
    overflow: hidden;
    font-size: 0.75rem;
  }
}

.duration {
  font-size: 0.75rem;
  opacity: $opacity--half;
  transition: $animation;
  display: flex;
  align-items: center;

  span {
    margin-left: calc($padding / 2);
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      fill: $grey--hover;
      margin-right: $title-padding;
    }
  }
}

.type {
  display: flex;
  align-items: center;
  margin-right: calc($padding / 2);

  .icon {
    margin-right: 0;
  }

  img {
    border-radius: none;
  }

  @include breakpoint('max-width', $breakpoint-medium) {
    max-width: 24px;
    min-width: 24px;
  }

  &Fullscreen {
    @include breakpoint('min-width', $breakpoint-large) {
      max-width: 24px;
      min-width: 24px;
    }

    @include breakpoint('max-width', $breakpoint-medium) {
      max-width: 24px;
      min-width: 24px;
    }
  }
}

.tags {
  display: flex;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
  -webkit-mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
  mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
  transition: $animation;

  .tag {
    margin-right: calc($padding / 2);
    text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    opacity: $opacity--hidden;
    line-height: 1;
    white-space: nowrap;
    text-transform: ellipsis;
    // overflow: hidden;
    transition: $animation;
    cursor: alias;

    &:hover,
    &:focus {
      background-color: $grey--hover;
    }
  }

  &Shown {
    span {
      opacity: $opacity--shown;
    }
  }
}

.youtube {
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @include border-radius($border-radius);

  &Shown {
    display: block;
    width: 100%;
    z-index: 1;
    @include center();
    @include border-radius(0);
    position: fixed;

    @include breakpoint('min-width', $breakpoint-large) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      aspect-ratio: $sixteen-nine;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.faded {
  opacity: $opacity--light;
}

.icon {
  width: 24px;
  min-width: 24px;
  max-height: 24px;
  margin-right: calc($padding / 4);
  fill: $white;
}

.preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: hidden;
  backdrop-filter: blur(25px);
  background-color: rgba(0, 0, 0, 0.5);
  @include border-radius($border-radius);

  &Inner {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 52rem;
    margin: auto;
    padding: $padding;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    color: $white;

    @include breakpoint('min-width', $breakpoint-medium) {
      padding: calc($padding * 2);
    }

    img {
      max-width: 100%;
      border-radius: $border-radius;
    }

    img,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    li,
    blockquote,
    pre,
    table {
      margin-bottom: calc($padding / 2);
    }

    a {
      color: $white;
      text-decoration: none;
      transition: $animation;
      font-weight: $font-weight--bold;
      box-shadow: 0 2px 0 0 $white;

      &:hover,
      &:focus {
        color: $black;
        background-color: $white;
        box-shadow: 0 0 0 4px $white;
      }
    }

    code {
      margin-top: $padding;
      margin-bottom: $padding;
      background-color: $grey;
      padding: $padding;
      border-radius: calc($border-radius / 2);
      display: inline-block;
      width: 100%;
      overflow: scroll;
      scrollbar-width: none;

      p,
      ol,
      ul,
      li {
        margin: 0;
      }
    }
  }

  &:hover,
  &:focus {
    .close {
      opacity: $opacity--shown;
    }
  }
}