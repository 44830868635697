@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.container {
  padding: $padding 0;

  @include breakpoint('min-width', $breakpoint-small) {
    padding: 0;
  }

  &.fullscreen {
    .article {
      @include breakpoint('min-width', $breakpoint-xxlarge) {
        width: calc(100% / 6);
      }
    }
  }
}

.outer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-bottom: $padding * 3;

  @include breakpoint('max-width', $breakpoint-medium) {
    overflow: hidden;
  }
}

.article {
  position: relative;
  display: block;
  min-height: 0;
  min-width: 0;
  width: 100%;
  border-radius: $border-radius;
  text-decoration: none;
  transition: $animation-card;
  border: 1px solid transparent;
  @include transition($animation);

  a {
    display: block;
    width: 100%;
    padding: calc($padding / 2);
    color: $white;
    text-decoration: none;
    overflow: hidden;
  }

  &:hover,
  &:focus {

    .duration,
    .description {
      opacity: $opacity--shown;
    }

    .glow {
      opacity: $opacity--shown;
      width: 90%;
      height: 90%;
    }

    .date {
      opacity: $opacity--semi;
    }
  }

  // &:active {
  //   outline: none;
  //   opacity: 0.33 !important;
  //   @include transform(translateY(2px));
  // }

  .tags,
  .duration {
    transition: $animation;

    span {
      transition: $animation;
    }
  }

  @include breakpoint('min-width', $breakpoint-small) {
    width: calc(100% / 2);
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    display: flex;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    // flex-direction: column;
    flex-direction: row;
    width: calc(100% / 3);
  }

  @include breakpoint('min-width', $breakpoint-large) {
    width: calc(100% / 4);
  }

  @include breakpoint('min-width', $breakpoint-xxlarge) {
    width: calc(100% / 5);
  }

  @include breakpoint('min-width', $breakpoint-xxxxlarge) {
    width: calc(100% / 6);
  }
}

.image {
  background-color: $grey;
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden;
  border-radius: calc($border-radius / 2);
  aspect-ratio: $sixteen-nine;

  @include breakpoint('max-width', $breakpoint-large) {
    margin-bottom: calc($padding / 2);
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    min-width: 170px;
    max-width: 170px;
    aspect-ratio: $four-three;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;
  }
}

.dividerTitle {
  // background-image: url("../../../public/footer--alt.png");
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center bottom;
  width: calc(100% - $padding * 2);
  margin: 0 $padding;
  padding: calc($padding / 2) 0;
  text-align: center;
  font-variant: small-caps;

  @include breakpoint('min-width', $breakpoint-large) {
    font-size: 1.75rem;
  }
}

.dividerImage {
  width: calc(100% - $padding * 2);
  margin: 0 $padding calc($padding / 2);
  text-align: center;
}

.title {
  margin: 0;
  margin-bottom: $title-padding;
  line-height: 1;
  @include line-clamp(2);
}

.description {
  font-size: 0.75rem;
  line-height: $line-height--small;
  opacity: 0.666;

  p {
    margin: 0;
    @include line-clamp(2);

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  font-size: 0.85rem;

  span {
    margin-right: $padding;
    line-height: 1;
  }

  img {
    max-width: 42px;
    min-width: 42px;
    max-height: 42px;
    border-radius: 42px;
    margin-right: calc($padding / 2);
    overflow: hidden;
    font-size: 0;
    border: 1px solid transparent;
    outline: none;
    background-color: $grey;
  }
}

.faded {
  opacity: $opacity--light;
}

.icon {
  width: 20px;
  margin-right: calc($padding / 4);
  fill: $white;
}