@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.twitch {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  width: 100%;
  border-radius: $border-radius;
  transition: $animation-card;
  padding-bottom: $padding;
  cursor: pointer;

  &:hover,
  &:focus {
    // background-color: $grey;
    // box-shadow: $shadow-edges;

    .offline {
      opacity: $opacity--shown;
    }

    .glow {
      opacity: $opacity--shown;
      width: 90%;
      height: 90%;
    }

    .date {
      opacity: $opacity--semi;
    }

    .duration {
      opacity: $opacity--shown;
    }
  }

  // &:active {
  //   outline: none;
  //   opacity: 0.33 !important;
  //   @include transform(translateY(2px));
  // }

  @include breakpoint('min-width', $breakpoint-medium) {
    width: calc(100% / 2);
  }

  @include breakpoint('min-width', $breakpoint-large) {
    width: calc(100% / 4);
  }

  @include breakpoint('min-width', $breakpoint-xxxxlarge) {
    width: calc(100% / 5);
  }

  a {
    height: 100%;
    width: 100%;
    // padding: calc($foundation / 1.25);
    color: $white;
    text-decoration: none;

    // @include breakpoint('min-width', $breakpoint-medium) {
    //   padding: $foundation;
    // }
  }

  &Large {
    @include breakpoint('min-width', $breakpoint-medium) {
      width: calc(100% / 3);
    }

    &Fullscreen {
      @include breakpoint('min-width', $breakpoint-xlarge) {
        width: calc(100% / 3);
      }

      @include breakpoint('min-width', $breakpoint-xxlarge) {
        width: calc(100% / 4);
      }

      @include breakpoint('min-width', $breakpoint-xxxlarge) {
        width: calc(100% / 5);
      }

      @include breakpoint('min-width', $breakpoint-xxxxlarge) {
        width: calc(100% / 6);
      }
    }
  }

  &Hidden {
    opacity: none;
    pointer-events: none;
    width: 100%;
    height: 0;
  }
}

.offline {
  opacity: 0.333;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    $animation-card;
}

.image {
  // background-color: $grey;
  position: relative;
  height: auto;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  border-radius: calc($border-radius / 2);
  aspect-ratio: $sixteen-nine;
  margin-bottom: calc($padding / 4 * -1);

  @include breakpoint('min-width', $breakpoint-medium) {
    padding: 4% 8%;
    background-image: url('../../../public/mainlivestream-frame_1080p.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  width: 100%;
  padding: $padding 0;

  @include breakpoint('min-width', $breakpoint-medium) {
    padding: calc($padding / 4) 8%;
    padding-bottom: $padding;
    padding-top: 0;
  }

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;
  }
}

.description {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  line-height: $line-height--small;

  p {
    opacity: 0.75;
    margin: 0;
    @include line-clamp(3);
  }
}

.title {
  display: flex;
  align-items: center;

  img {
    background-color: $grey;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: calc($padding / 4);
    font-size: 0;
  }
}

.duration {
  font-size: 0.75rem;
  opacity: $opacity--half;
  transition: $animation;
  display: flex;
  align-items: center;

  span {
    margin-left: calc($padding / 2);
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      fill: $grey--hover;
      margin-right: $title-padding;
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}