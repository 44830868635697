@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.music {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
  padding: calc($foundation / 2);
  border-radius: $border-radius;
  transition: $animation-card;
  box-shadow: 0 calc($padding / 2) 0 calc($padding / 2 * -1 + 1px) rgba(255, 255, 255, 0.05);
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover,
  &:focus {
    // background-color: $grey;
    // box-shadow: $shadow-edges;

    .date,
    .duration {
      opacity: $opacity--shown;
    }

    .glow {
      opacity: $opacity--shown;
      width: 90%;
      height: 90%;
    }
  }

  // &:active {
  //   outline: none;
  //   opacity: 0.33 !important;
  //   @include transform(translateY(2px));
  // }

  &Row {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    min-height: 0;
    min-width: 0;
    width: 100%;
    color: $white;
    text-decoration: none;
    overflow: hidden;
  }

  &Close {
    opacity: $opacity--hidden;
    @include transition($animation);
    // display: none;

    &Shown {
      // display: block;
      opacity: $opacity--shown;
    }

    &Hidden {
      opacity: $opacity--hidden;
      pointer-events: none;
      @include transition($animation);
      // display: none;
    }
  }
}

.meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: calc($padding / 2);

  @include breakpoint('min-width', $breakpoint-medium) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: calc(100% - 130px);
    max-width: calc(100% - 130px);
  }

  &Twitch {
    justify-content: flex-end;
  }
}

.date {
  transition: $animation;
}

.image {
  background-color: $grey;
  position: relative;
  width: 47px;
  min-width: 47px;
  height: auto;
  border-radius: calc($border-radius / 2);
  aspect-ratio: $one-one;
  overflow: hidden;
  font-size: 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    @include transition($animation);
  }

  &Add,
  &Close {
    width: 36px;
    @include center();
    fill: $white;
    opacity: $opacity--hidden;
    @include transition($animation);
    pointer-events: none;
  }

  &Close {
    fill: $red;
  }

  &:hover,
  &:focus {
    @include box-shadow($shadow-edges);

    img {
      opacity: 0.33;
      filter: blur(3px);
    }

    .imageAdd {
      opacity: $opacity--shown;
      z-index: 2;
    }

    .imageClose {
      opacity: $opacity--shown;
      z-index: 3;
    }
  }
}

.channel {
  justify-content: flex-start;

  &Twitch {
    min-width: 165px;

    .channel {
      max-width: fit-content;
      width: auto;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @include breakpoint('min-width', $breakpoint-medium) {
    align-items: center;
  }
}

.dividerTitle {
  // background-image: url("../../../public/footer--alt.png");
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center bottom;
  width: calc(100% - $padding * 2);
  margin: 0 $padding;
  padding: calc($padding / 2) 0;
  text-align: center;
  font-variant: small-caps;

  @include breakpoint('min-width', $breakpoint-large) {
    font-size: 1.75rem;
  }
}

.dividerImage {
  width: calc(100% - $padding * 2);
  margin: 0 $padding calc($padding / 2);
  text-align: center;
}

.sectionTitle {
  display: block;
  margin-top: 0;
  margin-bottom: $padding;
  padding: calc($padding / 2) $padding;
  box-shadow: $shadow-edges;
  border-radius: $border-radius;
  background-color: $grey;
  text-transform: lowercase;

  span {
    font-weight: $font-weight--normal
  }
}

.title {
  font-size: 0.9rem;
  // font-weight: $font-weight--medium;
  // letter-spacing: -0.025em;
  line-height: $line-height--title;
  margin: 0 0 calc($padding / 4);
  padding-right: calc($padding / 2);
  @include line-clamp(1);

  @include breakpoint('min-width', $breakpoint-medium) {
    min-width: 75%;
    max-width: 75%;
    margin: 0;
  }

  &Twitch {
    font-family: $font-weight--normal;
    font-size: 0.9rem;
    // font-weight: $font-weight--medium;
    // letter-spacing: -0.025em;
    line-height: $line-height--title;
    margin: 0 0 calc($padding / 4);
    padding-right: calc($padding / 2);
    @include line-clamp(1);

    @include breakpoint('min-width', $breakpoint-medium) {
      min-width: calc(100% - 165px);
      max-width: calc(100% - 165px);
      margin: 0;
    }
  }
}

.trigger,
.triggerClose {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.duration {
  font-size: 0.75rem;
  opacity: $opacity--half;
  transition: $animation;
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
  white-space: nowrap;
  min-width: 80px;
  max-width: 80px;
  padding-left: calc($padding / 2);

  span {
    display: flex;
    align-items: center;
    transition: $animation;
    line-height: 1;

    svg {
      width: 16px;
      fill: $grey--hover;
      margin-right: $title-padding;
    }
  }
}

.musicLists {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(10px);
  transition: filter 0.666s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  // @include breakpoint('min-width', $breakpoint-medium) {
  //   justify-content: flex-start;
  // }

  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(50% - 0.5rem);
    margin-bottom: $foundation;
    padding: calc($foundation / 2);
    border-radius: $border-radius;
    transition: $animation;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .tags,
    .headerTags {

      .tag,
      .headerTagsTag {
        opacity: $opacity--shown;
        max-width: 100%;
      }
    }

    &Margin {
      margin-bottom: $padding;
    }

    &:hover,
    &:focus {
      background-color: $grey;
      box-shadow: $shadow-edges;

      span {
        background-color: $grey--hover;
        box-shadow: $shadow-edges;
      }

      .musicLists {
        filter: blur(0) brightness(0.33);
      }

      .tags,
      .headerTags {
        mask-image: none;
      }

      .tag,
      .headerTagsTag {
        opacity: $opacity--shown;
        background-color: $white;
        color: $black;
        @include line-clamp(1);

        &:hover,
        &:focus {
          background-color: $white;
        }
      }
    }

    @include breakpoint('min-width', $breakpoint-large) {
      // display: block;
      // text-align: right;
      width: calc(100% / 7 - 1rem);
      margin-bottom: 0;
      padding: $foundation;

      .tags,
      .headerTags {
        // justify-content: flex-end;

        .tag,
        .headerTagsTag {
          margin-right: 0;
          margin-bottom: calc($padding / 6);
        }
      }

      &Margin {
        margin-bottom: $padding;
        // width: calc(100% / 6 - 1rem);
      }
    }
  }

  &Stats {
    &Margin {
      @include breakpoint('min-width', $breakpoint-medium) {
        width: calc(100% / 4 - 1rem);
      }

      @include breakpoint('min-width', $breakpoint-large) {
        width: calc(100% / 6 - 1rem);
      }

      @include breakpoint('min-width', $breakpoint-xxxxlarge) {
        width: calc(100% / 8 - 1rem);
      }
    }

    &.fullscreen {
      @include breakpoint('min-width', $breakpoint-medium) {
        width: calc(100% / 4 - 1rem);
      }

      @include breakpoint('min-width', $breakpoint-large) {
        width: calc(100% / 6 - 1rem);
      }

      @include breakpoint('min-width', $breakpoint-xxxxlarge) {
        width: calc(100% / 8 - 1rem);
      }
    }
  }
}

.grid {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  min-height: 96px;
  // background-color: rgba(0, 0, 0, 0.075);
  // border-radius: $border-radius;
  // box-shadow: $shadow-edges;
  // -webkit-backdrop-filter: blur(25px);
  // backdrop-filter: blur(25px);
  // margin-bottom: $padding;
  margin-top: calc($padding / 2);

  &Playlist {
    padding-bottom: $padding * 2;
  }

  &Top {
    @include breakpoint('min-width', $breakpoint-medium) {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    margin-top: 0;
    margin-bottom: $padding * 2;

    &NoMargin {
      margin-bottom: 0;
    }
  }

  @include breakpoint('min-width', $breakpoint-large) {
    margin-bottom: 0;
  }
}

.headerTags {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-y: scroll;
  scrollbar-width: none;
  transition: $animation;

  &Tag {
    // margin-right: calc($padding / 2);
    text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    line-height: 1;
    // opacity: $opacity--hidden;
    // white-space: nowrap;
    @include line-clamp(1);
    text-transform: ellipsis;
    // overflow: hidden;
    transition: $animation;
    cursor: alias;

    &:hover,
    &:focus {
      background-color: $grey--hover;
    }
  }

  &Shown {
    span {
      opacity: $opacity--shown;
    }
  }
}

.TagsForTags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  scrollbar-width: none;
  transition: $animation;

  &Tag {
    // margin-right: calc($padding / 2);
    text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    line-height: 1;
    // opacity: $opacity--hidden;
    white-space: nowrap;
    text-transform: ellipsis;
    overflow: hidden;
    margin-right: calc($padding / 2);
    transition: $animation;
    @include line-clamp(1);
    cursor: alias;

    &:hover,
    &:focus {
      background-color: $grey--hover;
    }
  }

  &Shown {
    span {
      opacity: $opacity--shown;
    }
  }
}

.sectionTitlePlaylist {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    height: 32px;
    width: 32px;
    border-radius: 32px;
    // background-color: rgba(0, 0, 0, 0.33);
    background-color: $grey;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight--bold;
    font-size: 1rem;
    line-height: 0;
    margin-left: calc($padding / 2);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    @include box-shadow($shadow-edges);
  }
}

.TagsForPlaylists {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  scrollbar-width: none;
  transition: $animation;

  &Tag {
    // margin-right: calc($padding / 2);
    text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    margin-right: calc($padding / 2);
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    line-height: 1.15;
    letter-spacing: -0.0125rem;
    // opacity: $opacity--hidden;
    white-space: nowrap;
    text-transform: ellipsis;
    overflow: hidden;
    transition: $animation;
    cursor: alias;
    @include line-clamp(1);
    text-align: left;

    &:hover,
    &:focus {
      background-color: $grey--hover;
    }

    &Inner {
      @include line-clamp(1);

    }
  }

  &Shown {
    span {
      opacity: $opacity--shown;
    }
  }
}

.tags {
  display: flex;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
  -webkit-mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
  mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
  transition: $animation;
  padding-left: calc($padding / 2);


  &Outer {
    min-width: calc(40% - 80px);
    max-width: calc(40% - 80px);
    -webkit-mask-image: linear-gradient(to left, black calc(100% - 6px), transparent 100%);
    mask-image: linear-gradient(to left, black calc(100% - 6px), transparent 100%);

    @include breakpoint('max-width', $breakpoint-medium) {
      display: none;
    }
  }

  .tag {
    margin-right: calc($padding / 2);
    text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    line-height: 1;
    opacity: $opacity--hidden;
    white-space: nowrap;
    text-transform: ellipsis;
    // overflow: hidden;
    transition: $animation;
    cursor: alias;

    &:hover,
    &:focus {
      background-color: $grey--hover;
    }
  }

  &Shown {
    span {
      opacity: $opacity--shown;
    }
  }
}