@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.container {
  padding: $padding 0;

  @include breakpoint('min-width', $breakpoint-small) {
    padding: 0;
  }
}

.article {
  position: relative;
  display: block;
  min-height: 0;
  min-width: 0;
  width: 100%;
  border-radius: $border-radius;
  text-decoration: none;
  transition: $animation-card;
  border: 1px solid transparent;
  @include transition($animation);

  a {
    text-decoration: none;
  }

  strong {
    font-weight: $font-weight--bold;
  }
}

.channel {
  display: inline-flex;
  box-shadow: $shadow-edges;
  background-color: rgb(0, 0, 0);
  color: $poe-color__unique;
  border: 1px solid $poe-color__unique;
  color: $poe-color__red;
  border: 1px solid $poe-color__red;
  background: white;
  position: relative;
  overflow: visible;

  &:before {
    @include transition(all 0.5s ease-in-out);
  }

  &:hover,
  &:focus {
    color: $poe-color__red;
    border: 1px solid $poe-color__red;
    background: white;
    @include box-shadow(0 0 30px -4px white);

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 50%;
      height: 20rem;
      width: 2px;
      background: transparent;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 20rem solid white;
      transform: translate(-50%, 0);
      z-index: -1;
      filter: blur(5px);
    }
  }
}

.mediaChannel {
  align-items: center;
  justify-content: center;
  font-size: 0.8275rem;
  padding: $span-padding;
  height: 24px;
  max-width: 75%;
  line-height: 0;
  letter-spacing: -0.0125rem;
  transition: $animation;
  cursor: default;
  min-width: 60px;
  text-align: center;
  font-family: $font-family--title;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-variant: small-caps;
  display: inline-flex;
  box-shadow: $shadow-edges;
  background-color: rgb(0, 0, 0);
  color: $poe-color__unique;
  border: 1px solid $poe-color__unique;
}

.content {
  padding: 0 $padding;
  padding-bottom: calc($padding * 2);
  max-width: 40rem;
  margin: auto;
  text-wrap: balance;

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;
  }
}

.dividerTitle {
  // background-image: url("../../../public/footer--alt.png");
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center bottom;
  width: calc(100% - $padding * 2);
  margin: 0 $padding;
  padding: calc($padding / 2) 0;
  text-align: center;
  font-variant: small-caps;

  @include breakpoint('min-width', $breakpoint-large) {
    font-size: 1.75rem;
  }
}

.divider {
  max-width: 40rem;
  margin: auto;
}

.dividerImage {
  width: calc(100% - $padding * 2);
  margin: 0 $padding calc($padding / 2);
  text-align: center;
}

.title {
  margin: 0;
  margin-bottom: $title-padding;
  line-height: 1;
  @include line-clamp(2);
}

.faded {
  opacity: $opacity--light;
}

sub {
  font-size: 0.75rem;
  font-weight: $font-weight--normal;
  opacity: $opacity--half;
  transition: $animation;
}