@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.video {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  width: 100%;
  // padding: calc($foundation / 1.25);
  // border-radius: $border-radius;
  margin-bottom: $padding;
  transition: $animation-card;
  cursor: pointer;

  &:hover,
  &:focus {
    // background-color: $grey;
    // box-shadow: $shadow-edges;

    .date {
      opacity: $opacity--semi;
    }

    .duration,
    .close {
      opacity: $opacity--shown;
    }

    .glow {
      opacity: $opacity--shown;
      width: 90%;
      height: 90%;
    }
  }

  // &:active {
  //   outline: none;
  //   opacity: 0.33 !important;
  //   @include transform(translateY(2px));
  // }

  @include breakpoint('min-width', $breakpoint-medium) {
    width: calc(100% / 2);
    // padding: $foundation;
  }

  @include breakpoint('min-width', $breakpoint-large) {
    width: calc(100% / 3);

    // &:nth-child(8n + 1) {
    //   width: 66.666667%;

    //   .image {
    //     aspect-ratio: 2.5/1;
    //   }
    // }

    // &:nth-child(8n + 2) {
    //   .image {
    //     height: 100%;
    //   }
    // }
  }

  &.fullscreen {
    @include breakpoint('min-width', $breakpoint-large) {
      width: calc(100% / 4);

      // &:nth-child(8n + 1) {
      //   .image {
      //     aspect-ratio: $sixteen-nine;
      //   }
      // }

      // &:nth-child(8n + 2) {
      //   .image {
      //     height: auto;
      //   }
      // }

      .title {
        @include line-clamp(3);
      }

      .metaInner {
        // width: auto;
        width: 100%;
        max-width: auto;
      }
    }

    @include breakpoint('min-width', $breakpoint-xxlarge) {
      width: calc(100% / 5);
    }

    @include breakpoint('min-width', $breakpoint-xxxlarge) {
      width: calc(100% / 6);
    }

    @include breakpoint('min-width', $breakpoint-xxxxlarge) {
      width: calc(100% / 8);
    }
  }
}

.youtube {
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  height: calc(100svh - $nav-height--mobile);
  @include border-radius($border-radius);

  @include breakpoint('min-width', $breakpoint-large) {
    height: 100%;
  }

  @supports (-webkit-touch-callout: none) {
    height: calc(100svh - $nav-height--mobile);
  }

  @include breakpoint('max-height', '500px') {
    height: 100%;
  }

  &Shown {
    display: block;
    width: 100%;
    z-index: 3;
    @include border-radius(0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;

    @supports (-webkit-touch-callout: none) {
      bottom: $nav-height;
    }

    @include breakpoint('min-width', $breakpoint-large) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      aspect-ratio: $sixteen-nine;
      @include center();
      position: fixed;
      padding: 4.5% 8.25%;
      // padding: 5% 8.5%;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.image {
  // background-color: $grey;
  position: relative;
  height: auto;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  border-radius: calc($border-radius / 2);
  aspect-ratio: $sixteen-nine;
  margin-bottom: calc($padding / 4 * -1);

  @include breakpoint('min-width', $breakpoint-large) {
    background-image: url('../../../public/frame.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 100%;
    max-height: auto;
    padding: 4% 8%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  width: 100%;
  padding-top: calc($padding / 4);
  padding-bottom: calc($padding / 4);

  @include breakpoint('min-width', $breakpoint-large) {
    padding-bottom: $padding;
    padding: calc($padding / 4) 8%;
  }

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;
  }
}

.description {
  font-size: 0.9rem;
  line-height: $line-height--small;
  opacity: 0.75;
  text-wrap: balance;

  @include breakpoint('min-width', $breakpoint-medium) {
    height: 100%;
  }

  p {
    margin: 0;
    @include line-clamp(2);
  }
}

.duration {
  font-size: 0.75rem;
  opacity: $opacity--half;
  transition: $animation;
  display: flex;
  align-items: center;

  span {
    margin-left: calc($padding / 2);
    display: flex;
    align-items: center;
    white-space: nowrap;

    svg {
      width: 16px;
      fill: $grey--hover;
      margin-right: $title-padding;
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85rem;
  margin-bottom: calc($padding / 4);

  img {
    // background-color: $grey;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    // border-radius: 20px;
    overflow: hidden;
    margin-right: $title-padding;
  }

  &Inner {
    display: flex;
    align-items: center;
    max-width: 75%;
    width: 100%;
    overflow: hidden;
    font-size: 0.75rem;
  }
}

.faded {
  opacity: $opacity--light;
}