@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.poe {
  display: flex;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  width: 100%;
  padding: calc($foundation / 1.25);
  border-radius: $border-radius;
  transition: $animation-card;

  &:hover,
  &:focus {
    background-color: $grey;
    box-shadow: $shadow-edges;

    .date {
      opacity: $opacity--semi;
      }

    .tags {
      .tag {
        opacity: $opacity--shown;
        }
    }

    .challenges {
      opacity: $opacity--shown;
    }

    .close {
      opacity: $opacity--shown;
    }
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    width: calc(100% / 3);
    padding: $foundation;
  }

  &.fullscreen {
    @include breakpoint('min-width', $breakpoint-large) {
      width: calc(100% / 4);
    }

    @include breakpoint('min-width', $breakpoint-xxlarge) {
      width: calc(100% / 5);
    }

    @include breakpoint('min-width', $breakpoint-xxxxlarge) {
      width: calc(100% / 6);
    }
  }
}

.youtube {
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  height: calc(100svh - $nav-height);
  @include border-radius($border-radius);

  @include breakpoint('min-width', $breakpoint-large) {
    height: 100%;
  }

  @supports (-webkit-touch-callout: none) {
    height: calc(100svh - $nav-height);
  }

  @include breakpoint('max-height', '500px') {
    height: 100%;
  }

  &Shown {
    display: block;
    width: 100%;
    z-index: 3;
    @include border-radius(0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;

    @supports (-webkit-touch-callout: none) {
      bottom: $nav-height;
    }

    @include breakpoint('min-width', $breakpoint-large) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      aspect-ratio: $sixteen-nine;
      @include center();
      position: fixed;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.image {
  background-color: $grey;
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: $sixteen-nine;
  border-radius: calc($border-radius / 2);
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: $animation;
    box-shadow: $shadow-edges--shadow-only;
  }

  &Meta {
    display: flex;
    position: absolute;
    top: calc($padding / 4);
    left: calc($padding / 4);
  }
}

.content {
  padding: calc($padding / 4) 0 0;
  width: 100%;
}

.title {
  font-size: 1.1rem;
  letter-spacing: -0.025em;
  line-height: $line-height--title;
  margin: 0;
  @include line-clamp(3);
}

.description {
  margin-bottom: calc($padding / 4);
  font-size: 0.85rem;
  line-height: $line-height--small;
  opacity: 0.75;
  @include line-clamp(1);

  p {
    margin: 0;
  }

  strong {
    font-weight: $font-weight--bold;
  }
}

.duration {
  font-size: 0.75rem;
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // text-transform: lowercase;
}

.date {
  font-size: 0.65rem;
  font-weight: $font-weight--bold;
  color: $white;
  margin-right: calc($padding / 4);
  background-color: $black;
  border-radius: calc($border-radius / 4);
  transition: $animation;
  padding: $span-padding;
  opacity: $opacity--hidden;
  text-transform: lowercase;
}

.tags {
  display: flex;
  align-items: center;

  .tag {
    display: flex;
    align-items: center;
    margin-right: calc($padding / 4);
    // text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    white-space: nowrap;
    text-transform: ellipsis;
    overflow: hidden;
    line-height: 1;
    text-decoration: none;
    color: $white;
    opacity: $opacity--hidden;
    transition: $animation;

    &:hover,
    &:focus {
      box-shadow: $shadow-edges;
      background-color: $grey--hover;
      // @include transform(translateY(-($padding / 4)));
    }

    svg {
      max-width: 18px;
      height: 18px;
      fill: $white;
    }
  }
}

.challenges {
  font-size: 0.65rem;
  font-weight: $font-weight--bold;
  color: $white;
  background-color: $black;
  border-radius: calc($border-radius / 4);
  padding: $span-padding;
  opacity: $opacity--hidden;
  text-transform: lowercase;
  transition: $animation;
  margin-right: calc($padding / 4);
  // display: flex;
  // align-items: center;
  // text-transform: lowercase;
  // font-size: 0.75rem;
  // padding: $span-padding;
  // border-radius: calc($border-radius / 2);
  // background-color: $grey;
  // white-space: nowrap;
  // text-transform: ellipsis;
  // overflow: hidden;
  // text-decoration: none;

  // &:hover,
  // &:focus {
  //   box-shadow: $shadow-edges;
  //   background-color: $grey--hover;
  //   @include transform(translateY(-($padding / 4)));
  // }

  .faded {
    opacity: .7;
  }

  svg {
    max-width: 18px;
    height: 18px;
    fill: $white;
  }
}

.faded {
  opacity: $opacity--light;
}