@import 'globals.scss';
@import 'mixins.scss';

html {
  margin: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  // cursor: none;
}

@supports not (-webkit-touch-callout: none) {
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-corner {
    display: none;
    width: 0;
  }
}

html,
body {
  font-family: $font-family;
  font-weight: $font-weight--normal;
  font-size: $font-size--body;
  // cursor: none;
}

body {
  background-color: $black;
  // background-image: url("../../public/wallpaper.jpg");
  background-size: cover;
  background-position: center;
  color: $white;
  touch-action: none;
}

h1,
h2,
h3,
h4 {
  font-family: $font-family--title;
  line-height: $line-height--title;
  letter-spacing: -0.0425rem;
  color: $white--hover;
  margin: 0;
}

.main {
  position: relative;
  display: flex;
  height: 100vh;
  overflow: hidden;

  // @include breakpoint('min-width', $breakpoint-medium) {
  //   @include breakpoint('max-height', '500px') {
  //     padding-bottom: $padding * 1.125;
  //   }
  // }

  @include breakpoint('min-width', $breakpoint-large) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $padding;
  }

  &Inner {
    width: 100%;
    height: auto;
    max-height: 100%;
    @include transition(all 0.25s ease-in-out);
    // @include breakpoint('min-width', $breakpoint-medium) {
    //   overflow: hidden;
    // }

    @include breakpoint('min-width', $breakpoint-medium) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &.fullscreen {
      height: 100%;
      max-height: 100%;
    }
  }
}

.background {
  background-size: cover;
  background-position: center;
  position: fixed;
  top: -5%;
  left: -5%;
  right: -5%;
  bottom: -5%;
  z-index: -1;
  filter: blur(3px);

  @include breakpoint('max-width', $breakpoint-medium) {
    filter: blur(5px);
    opacity: 0.3;
  }
}

.inside {
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  @include transition(opacity 0.333s ease-in-out);
  // margin: $padding;

  @include breakpoint('min-width', $breakpoint-large) {
    aspect-ratio: $sixteen-nine;
    max-width: 84.5%;
    margin: 4% auto;
    height: auto;
    // max-height: 82%;
    // max-width: 82%;
    // margin: 4% 8% 5%;
    // height: calc(100% - 16%);
    // margin: auto;
    // height: 84%;
    // max-width: 71%;
    // @include center();

    @include breakpoint('max-height', $breakpoint-large) {
      margin: 3% auto;
      max-width: 82.5%;
      height: 86%;
    }
  }

  &Locked {
    overflow-y: hidden;
    pointer-events: none;
    opacity: 0.1;
    animation-name: blur;
    animation-duration: 1s;
    animation-delay: 666ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;

    @keyframes blur {
      from {filter: blur(0);}
      to {filter: blur(15px);}
    }
  }

  // @include breakpoint('min-width', $breakpoint-small) {
  //   @include border-radius($border-radius);
  // }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-corner,
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-corner {
    display: none;
    width: 0;
    height: 0;
  }
}

.container {
  position: relative;
  max-width: $max-width;
  width: 100%;
  height: calc(100vh - $nav-height--mobile);
  height: calc(100svh - $nav-height--mobile);
  padding: $padding $padding 0 $padding;
  // margin: auto;
  overflow: hidden;
  background-color: transparent;
  // padding: 0 calc($padding / 2);
  border: 1px solid transparent;
  // -webkit-backdrop-filter: blur(0);
  // backdrop-filter: blur(0);
  // box-shadow: $shadow-edges;

  // @supports (-webkit-touch-callout: none) {
  //   height: calc(100svh - $nav-height);
  // }

  @include breakpoint('min-width', $breakpoint-medium) {
    height: 100%;
    // padding: 5% 8.75% 5% 8.75%;
    // padding: 0;
    @include transition(all 0.666s ease-in-out);
    // padding: calc($padding * 2 - $foundation);
    // padding: 6vh 5.5vw;
    // border-radius: $border-radius * 2;
    // margin-bottom: $padding;

    // @include breakpoint('max-height', '500px') {
      // padding: calc($padding / 1.25);
      // border-radius: 0;
      // margin-bottom: 0;
    // }
  }

  @include breakpoint('min-width', $breakpoint-large) {
    background-image: url("../../public/mainlivestream-frame_1080p.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // padding: $padding * 3 - $foundation;
    // margin-bottom: $padding;
    height: 100%;
    max-height: 100%;
    aspect-ratio: $sixteen-nine;
  }

  @include breakpoint('min-width', $breakpoint-xlarge) {
    height: auto;
  }

  @include breakpoint('min-width', $breakpoint-xxxxlarge) {
    max-width: 92rem;
    // padding: $padding * 4 - $foundation;
  }

  // &:hover,
  // &:focus {
  //   box-shadow: $shadow-edges--hover;
  // }

  &.fullscreen {
    @include breakpoint('min-width', $breakpoint-large) {
      background: none;
      max-width: 100%;
      height: 100%;
      height: calc(100vh - 12rem);
      padding: calc($padding * 3 - $foundation);
      border: 1px solid #a38d6d42;
      // -webkit-backdrop-filter: blur(40px);
      // backdrop-filter: blur(40px);
      aspect-ratio: inherit;
    }

    .inside {
      aspect-ratio: inherit;
      position: inherit;
      @include transform(none);
      top: 0;
      height: 100%;
      max-width: 100%;
      left: 0;
      margin: 0;
    }
  }

}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #ffffff10;
  max-width: $max-width / 1.5;
  width: 100%;
  // overflow: hidden;
  @include transition(all 0.666s ease-in-out);

  @include breakpoint('min-width', $breakpoint-large) {
    padding-bottom: 0;
    border-bottom: none;
  }

  // @include breakpoint('min-width', $breakpoint-xxxxlarge) {
  //   max-width: 92rem;
  // }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  scrollbar-width: none;
  height: $nav-height--mobile;
  @include transition(all 0.666s ease-in-out);

  @supports (-webkit-touch-callout: none) {
    padding-bottom: calc($padding / 1.5);
  }

  @include breakpoint('max-width', $breakpoint-medium) {
    overflow: auto;
    overflow-x: scroll;
    background-color: #00000020;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    height: $nav-height;
    // box-shadow: $shadow-edges;
    // border-radius: $border-radius * 2;

    @include breakpoint('max-height', '500px') {
      height: $nav-height--mobile;
      padding: 0;
      // border-radius: 0;
    }
  }

  @include breakpoint('min-width', $breakpoint-large) {
    white-space: inherit;
  }
}

// .inner {
//   -webkit-mask-image: linear-gradient(to bottom, black calc(100% - 0.625rem), transparent 100%);
// }

.icons {
  position: relative;
  width: 45px;
  min-height: 45px;
  font-family: $font-family--title;
  line-height: $line-height--title;
  border-radius: 45px;
  // aspect-ratio: $one-one;
  fill: $white;
  // opacity: $opacity-start;
  display: inline-block;
  padding: 0 calc($padding / 2) calc($padding / 6);
  margin: 0 calc($padding / 2);
  display: flex;
  color: $white;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  @include transition(all 0.333s ease-in-out);
  // overflow: visible;
  // @include transform(scale(0.95));

  a {
    color: $white;
  }

  svg,
  img {
    position: relative;
    // width: 100%;
    transition: $animation;
  }

  span {
    font-size: 0.725rem;
    display: block;

    @include breakpoint('min-width', $breakpoint-medium) {
      font-size: 0.775rem;
    }

    @include breakpoint('min-width', $breakpoint-large) {
      font-size: 0.825rem;
      margin-top: calc($padding / 2);
    }
  }

  &:hover,
  &:focus {
    img {
      @include transform(translateY(-10px));
    }

    @include breakpoint('min-width', $breakpoint-medium) {
      svg {
        @include transform(scale(1.333) translateY(-25%));
      }
    }
  }

  &:active {
    @include transition(all 0.1s ease-in-out);

    img {
      @include transform(translateY(0));
    }

    @include breakpoint('min-width', $breakpoint-medium) {
      svg {
        @include transform(scale(0.95) translateY(5%));
      }
    }
  }

  @include breakpoint('min-width', $breakpoint-large) {
    display: inherit;
    margin: 0;
  }

  &Back {
    margin-left: 0;
  }

  &Hide {
    @include breakpoint('max-width', $breakpoint-large) {
      display: none;
    }
  }

  &DesktopHide {
    @include breakpoint('min-width', $breakpoint-large) {
      display: none;
    }
  }

  &MobileHide {
    @include breakpoint('max-width', $breakpoint-large) {
      display: none;
    }
  }

  &Show {
    @include breakpoint('min-width', $breakpoint-large) {
      display: none;
    }
  }

  &Metal {
    padding: calc($padding / 10);
  }

  &Berserk {
    padding: calc($padding / 2) $title-padding;
  }
}

.active {
  // background-color: $grey;
  // box-shadow: $shadow-edges;
  opacity: $opacity-end;
  overflow: visible;
  // @include transform(scale(1.125));

  span {
    opacity: $opacity-end;
  }

  // &:hover,
  // &:focus {
  //   box-shadow: inset 0 0 1px #ffffff65,
  //     0 0 40px -5px #00000025;
  // }
}

.tooltip {
  @include center(true, false);
  bottom: -20%;
  font-size: 0.75rem;
  // opacity: $opacity--hidden;
  white-space: nowrap;
  transition: $animation;

  @include breakpoint('max-width', $breakpoint-medium) {
    display: none;
  }
}

.version {
  position: fixed;
  display: flex;
  align-items: center;
  font-size: .5rem;
  text-transform: uppercase;
  line-height: 1;
  padding-bottom: calc($padding / 4);
  z-index: 3;
  font-weight: $font-weight--bold;
  bottom: 0;
  cursor: pointer;

  @include breakpoint('max-width', $breakpoint-medium) {
    display: none;
  }

  @supports (-webkit-touch-callout: none) {
    left: $padding * 0.975;
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    font-size: .6rem;
    right: 0;
  }

  @include breakpoint('min-width', $breakpoint-large) {
    padding-bottom: calc($padding / 2);
    padding-right: calc($padding / 4);
  }

  svg {
    width: 13px;
    margin-right: $title-padding;
    fill: $white;
  }

  &Inner {
    margin: 0 $title-padding;
    display: flex;
    align-items: center;
    opacity: .25;
    @include transition($animation);

    @include breakpoint('min-width', $breakpoint-medium) {
      margin: 0 calc($padding / 2);
    }

    &:hover,
    &:focus {
      opacity: $opacity-end;
    }
  }
}

// .privacy {
//   // position: fixed;
//   align-items: center;
//   font-size: .6rem;
//   line-height: 1;
//   padding-bottom: calc($padding / 4);
//   z-index: 3;
//   font-weight: $font-weight--bold;
//   bottom: 0;
//   left: 0;
//   text-transform: uppercase;
//   display: none;

//   @include breakpoint('min-width', $breakpoint-medium) {
//     display: flex;
//   }

//   @include breakpoint('min-width', $breakpoint-large) {
//     padding-bottom: calc($padding / 2);
//     padding-left: calc($padding / 4);
//   }

//   svg {
//     width: 13px;
//     margin-right: $title-padding;
//     fill: $white;
//   }

//   &Inner {
//     margin: 0 calc($padding / 2);
//     display: flex;
//     align-items: center;
//     opacity: .25;
//     @include transition($animation);

//     &:hover,
//     &:focus {
//       opacity: $opacity-end;
//     }
//   }
// }

.playerMotion {
  @include breakpoint('min-width', $breakpoint-medium) {
    margin-left: calc($padding / 2);
  }

  @include breakpoint('min-width', $breakpoint-large) {
    margin-left: $padding * 1.5;
  }
}

.tagNav {
  padding: calc($padding / 4);
  padding-right: calc($padding / 2);
  font-size: 0.75rem;
  transition: $animation-card;
  display: flex;
  align-items: center;
  box-shadow: $shadow-edges;
  border-radius: $border-radius;
  background-color: $grey;
  // @include center(true, false);
  position: absolute;
  bottom: calc($padding / 2);
  left: 7%;
  z-index: 8;
  opacity: $opacity--shown;
  cursor: pointer;

  @include breakpoint('max-width', $breakpoint-large) {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }

  // @include breakpoint('min-width', $breakpoint-xxxxlarge) {
  //   bottom: $padding;
  // }

  // @supports (-webkit-touch-callout: none) {
  //   bottom: calc($nav-height--mobile + calc($padding / 2));
  // }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 240px;
  }

  svg {
    width: 16px;
    min-width: 16px;
    fill: $grey--hover;
    margin-right: $title-padding;
  }

  &:hover,
  &:focus {
    opacity: $opacity--shown;
    // box-shadow: $shadow-edges--hover;
    background-color: $grey--hover;

    .tagNavShown {
      display: none;
    }

    .tagNavClose {
      display: flex;
    }
  }

  &Close {
    display: none;
  }
}

.divider {
  height: 100%;
  background: white;
  display: flex;
  height: calc($nav-height / 3);
  width: 1px;
  opacity: 0.125;
  min-width: 1px;

  @include breakpoint('max-width', $breakpoint-large) {
    margin: 0 calc($padding / 2);
  }
}