@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.container {
  padding: $padding 0;

  @include breakpoint('min-width', $breakpoint-small) {
    padding: 0;
  }
}

.outer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-bottom: $padding * 3;

  @include breakpoint('max-width', $breakpoint-medium) {
    overflow: hidden;
  }
}

.article {
  position: relative;
  display: block;
  min-height: 0;
  min-width: 0;
  width: 100%;
  // border-radius: $border-radius;
  text-decoration: none;
  transition: $animation-card;
  padding: $foundation;
  color: $white;

  &:hover,
  &:focus {
    // background-color: $grey;
    // box-shadow: $shadow-edges;

    .shortcut {
      span {
        opacity: $opacity--shown;
      }
    }

    .glow {
      opacity: $opacity--shown;
      width: 90%;
      height: 90%;
    }
  }

  // &:active {
  //   outline: none;
  //   opacity: 0.33 !important;
  //   @include transform(translateY(2px));
  // }

  .shortcut {
    transition: $animation;

    span {
      transition: $animation;
    }
  }

  @include breakpoint('min-width', $breakpoint-small) {
    width: calc(100% / 2);
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    display: flex;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    // flex-direction: column;
    flex-direction: row;
    width: calc(100% / 3);
  }

  @include breakpoint('min-width', $breakpoint-xxlarge) {
    width: calc(100% / 4);
  }

  @include breakpoint('min-width', $breakpoint-xxxxlarge) {
    width: calc(100% / 5);
  }

  // @include breakpoint('min-width', $breakpoint-xxxxlarge) {
  //   width: calc(100% / 6);
  // }
}

.title {
  margin: 0;
  line-height: $line-height--title;
  @include line-clamp(3);
}

.description {
  font-size: 0.75rem;
  line-height: $line-height--small;
  opacity: 0.666;

  p {
    margin: calc($padding / 8) 0 calc($padding / 4) 0;
    @include line-clamp(3);
  }
}

.meta {
  display: flex;
  align-items: flex-start;
  font-size: 0.85rem;

  span {
    margin-right: $padding;
    line-height: 1;
  }

  svg {
    max-width: 32px;
    min-width: 32px;
    margin-right: calc($padding / 2);
    fill: $white;
  }
}

.dividerTitle {
  width: calc(100% - $padding * 2);
  margin: 0 $padding;
  padding: calc($padding / 2) 0;
  text-align: center;
  font-variant: small-caps;

  @include breakpoint('min-width', $breakpoint-large) {
    font-size: 1.75rem;
  }
}

.dividerImage {
  width: calc(100% - $padding * 2);
  margin: 0 $padding calc($padding / 2);
  text-align: center;
}

.shortcut {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  padding: $span-padding;
  background-color: $grey;
  height: 28px;
  line-height: 0;
  letter-spacing: -0.0125rem;
  transition: $animation;
  border: 1px solid transparent;
  cursor: pointer;
  min-width: 60px;
  text-align: center;
  font-family: $font-family--title;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-variant: small-caps;
  margin: calc($padding / 4) 0;

  code {
    font-family: $font-family--title;
    width: auto;
    margin: auto;
    line-height: 0;
  }

  &:hover,
  &:focus {
    box-shadow: $shadow-edges;
    background-color: rgb(0, 0, 0);
    color: $poe-color__unique;
    border: 1px solid $poe-color__unique;
  }
  // text-transform: lowercase;
}

.faded {
  opacity: $opacity--light;
}