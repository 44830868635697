@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.search {
  background-image: url('../../../public/search-alt.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @include center();
  top: calc(100% / 2.5);
  position: fixed;
  z-index: 9;
  width: 100%;

  @include breakpoint('min-width', $breakpoint-small) {
    max-width: $breakpoint-small;
  }

  input {
    width: 100%;
    border: none;
    white-space: nowrap;
    background-color: transparent;
    color: $white;
    font-size: $font-size--body * 1.5;
    font-family: $font-family--title;
    line-height: $padding * 3.5;
    padding: 0 $padding;
    height: 100px;
    // padding-right: calc(2rem + 28px);
    // backdrop-filter: blur(25px);
    // border-radius: $border-radius;
    // box-shadow: 0 3px 60px -3px rgba(0, 0, 0, 0.6);
    transition: $animation;

    &:focus {
      outline: none;
      // box-shadow: 0 3px 30px -1px rgba(0, 0, 0, 0.6);
    }
  }

  // &Icon {
  //   @include center(false, true);
  //   right: calc($padding * 1.5);
  //   width: 28px;
  //   fill: $white;
  // }
}

.channel {
  justify-content: flex-start;
}

.box {
  position: relative;

  &Inner {
    margin: 0 1.5rem;
  }

  &Note {
    text-align: right;
    font-size: 0.725rem;
    opacity: 0.666;
    margin-top: calc($padding / 6);
    @include center(true, false);

    strong {
      font-weight: $font-weight--bold;
    }

    p {
      margin: 0;
    }
  }

  &Close {
    position: absolute;
    top: -0.625rem;
    right: 0.7275rem;
    width: 32px;
    height: 32px;
    fill: $white;
    z-index: 10;
    cursor: pointer;
    // opacity: $opacity--half;
    @include transition($animation);

    &:hover,
    &:focus {
      opacity: $opacity--shown;
      filter: brightness(1.25);
    }
  }
}

.icon {
  fill: $white;
}

.outer {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  width: 100%;
  cursor: pointer;

  &:hover,
  &:focus {
    .glow,
    .close,
    .songDuration {
      opacity: $opacity--shown;
    }
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    width: calc(100% / 2);
  }

  @include breakpoint('min-width', $breakpoint-large) {
    width: calc(100% / 3);
  }

  &.fullscreen {
    display: flex;
    align-items: stretch;
    // height: 100%;

    @include breakpoint('min-width', $breakpoint-large) {
      width: calc(100% / 4);

      .article {
        height: 100%;
      }

      .content {
        // padding-left: 0;
        // padding-top: calc($padding / 3);

        &Inner {
          padding-top: $title-padding;
        }
      }

      .title {
        padding-bottom: $title-padding;
        @include line-clamp(3);
      }

      .description p {
        @include line-clamp(4);
      }
    }

    @include breakpoint('min-width', $breakpoint-xxlarge) {
      width: calc(100% / 5);
    }

    @include breakpoint('min-width', $breakpoint-xxxlarge) {
      width: calc(100% / 6);
    }
  }
}

.article {
  display: block;
  min-height: 0;
  min-width: 0;
  padding-bottom: calc($padding * 2);
  width: 100%;
  border-radius: $border-radius;
  color: $white;
  text-decoration: none;
  transition: $animation;

  &:hover,
  &:focus {
    .tags {
      span {
        opacity: $opacity--shown;
      }
    }

    .duration {
      opacity: $opacity--shown;
    }

    .date {
      opacity: $opacity--semi;
    }
  }
}

.image {
  position: relative;
  height: auto;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  border-radius: calc($border-radius / 2);
  aspect-ratio: $sixteen-nine;
  margin-bottom: calc($padding / 2);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    padding: 4% 8%;
    background-image: url('../../../public/frame.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: calc($padding / 4 * -1);
  }
}

.content {
  width: 100%;
  padding-top: calc($padding / 4);
  padding-bottom: calc($padding / 4);

  @include breakpoint('min-width', $breakpoint-large) {
    padding-bottom: $padding;
    padding: calc($padding / 4) 8%;
  }

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;
  }
}

.title {
  margin: 0;
  line-height: $line-height--title;
  color: $white--hover;
  @include line-clamp(3);
}

.description {
  font-size: 0.9rem;
  line-height: $line-height--small;
  opacity: 0.75;
  text-wrap: balance;

  p {
    margin: 0;
    @include line-clamp(2);
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85rem;
  margin-bottom: calc($padding / 4);

  img {
    // background-color: $grey;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    // border-radius: 20px;
    overflow: hidden;
    margin-right: $title-padding;
  }

  &Inner {
    display: flex;
    align-items: center;
    // max-width: 75%;
    width: 100%;
    overflow: hidden;
    font-size: 0.75rem;

    span {
      transition: $animation;
    }

    &:hover,
    &:focus {
      span {
        // background-color: $grey--hover;
        // @include box-shadow($shadow-edges);
      }
    }

    span {
      padding: $span-padding;
      border-radius: calc($border-radius / 2);
      background-color: $grey;
    }

    @include breakpoint('min-width', $breakpoint-medium) {
      // width: auto;
      max-width: auto;
    }
  }
}

.duration {
  font-size: 0.75rem;
  opacity: $opacity--half;
  transition: $animation;
  display: flex;
  align-items: center;

  span {
    margin-left: calc($padding / 2);
    display: flex;
    align-items: center;
    white-space: nowrap;

    svg {
      width: 16px;
      fill: $grey--hover;
      margin-right: $title-padding;
    }
  }
}

.type {
  display: flex;
  align-items: center;
  margin-right: $title-padding;
  width: 24px;
  height: 24px;
  position: relative;
  aspect-ratio: 1;
  margin-right: 3px;

  .icon {
    margin-right: 0;
    @include center();
  }

  img {
    border-radius: none;
  }
}

.glow {
  opacity: $opacity--hidden;
  transition: $animation;
  background-color: $grey;
  z-index: -1;
  width: 90%;
  height: 90%;
  filter: blur(25px);
  @include center();
  @include border-radius(1000px);
}

.tags {
  display: flex;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
  -webkit-mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
  mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
  transition: $animation;

  span {
    margin-right: calc($padding / 2);
    text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    opacity: $opacity--hidden;
    white-space: nowrap;
    text-transform: ellipsis;
    // overflow: hidden;
    transition: $animation;
    cursor: alias;

    &:hover,
    &:focus {
      background-color: $grey--hover;
    }
  }

  &Shown {
    span {
      opacity: $opacity--shown;
    }
  }
}

.youtube {
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  height: calc(100svh - $nav-height--mobile);
  @include border-radius($border-radius);

  @include breakpoint('min-width', $breakpoint-large) {
    height: 100%;
  }

  @supports (-webkit-touch-callout: none) {
    height: calc(100svh - $nav-height--mobile);
  }

  @include breakpoint('max-height', '500px') {
    height: 100%;
  }

  &Shown {
    display: block;
    width: 100%;
    z-index: 3;
    @include border-radius(0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;

    @supports (-webkit-touch-callout: none) {
      bottom: $nav-height;
    }

    @include breakpoint('min-width', $breakpoint-large) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      aspect-ratio: $sixteen-nine;
      @include center();
      position: fixed;
      padding: 4.5% 8.25%;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.faded {
  opacity: $opacity--light;
}

.icon {
  width: 18px;
  min-width: 18px;
  max-height: 17px;
  margin-right: calc($padding / 4);
  fill: $white;
}

// .preview {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: 2;
//   overflow: hidden;
//   backdrop-filter: blur(25px);
//   background-color: rgba(0, 0, 0, 0.5);
//   @include border-radius($border-radius);

//   &Inner {
//     position: relative;
//     height: 100%;
//     width: 100%;
//     max-width: 52rem;
//     margin: auto;
//     padding: $padding;
//     overflow: hidden;
//     overflow-y: scroll;
//     scroll-behavior: smooth;
//     scrollbar-width: none;
//     color: $white;

//     @include breakpoint('min-width', $breakpoint-medium) {
//       padding: calc($padding * 2);
//     }

//     img {
//       max-width: 100%;
//       border-radius: $border-radius;
//     }

//     img,
//     p,
//     h1,
//     h2,
//     h3,
//     h4,
//     h5,
//     h6,
//     ul,
//     ol,
//     li,
//     blockquote,
//     pre,
//     table {
//       margin-bottom: calc($padding / 2);
//     }

//     a {
//       color: $white;
//       text-decoration: none;
//       transition: $animation;
//       font-weight: $font-weight--bold;
//       box-shadow: 0 2px 0 0 $white;

//       &:hover,
//       &:focus {
//         color: $black;
//         background-color: $white;
//         box-shadow: 0 0 0 4px $white;
//       }
//     }

//     code {
//       margin-top: $padding;
//       margin-bottom: $padding;
//       background-color: $grey;
//       padding: $padding;
//       border-radius: calc($border-radius / 2);
//       display: inline-block;
//       width: 100%;
//       overflow: scroll;
//       scrollbar-width: none;

//       p,
//       ol,
//       ul,
//       li {
//         margin: 0;
//       }
//     }
//   }

//   &:hover,
//   &:focus {
//     .close {
//       opacity: $opacity--shown;
//     }
//   }
// }

.message {
  // font-family: "Hacker", "Hack", monospace;
  // mix-blend-mode: overlay;
  aspect-ratio: $sixteen-nine;

  span {
    @include center();
    font-weight: $font-weight--bold;
  }
}

.loader {
  width: 90px;
  margin: $padding;
  opacity: 0.75;
}