@use "sass:math";

// import font from /public/fonts/Fontin-Regular.otf
@font-face {
  font-family: "Fontin";
  src: url("../../public/fonts/Fontin-Regular.otf") format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;600&display=swap');

$foundation: 1rem;
$padding: calc($foundation * 1.25);
$large-padding: 10vh;
$span-padding: 0 0.5rem;
$title-padding: calc($padding / 4);

$max-width: 90rem;
$max-height: 582.1px;
$max-height--header: 46.6px;
$border-radius: 0;
$nav-height: calc($foundation * 5.5);
$nav-height--mobile: calc($foundation * 5);

$font-family: "Man Rope", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-family--title: "Fontin", Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;

$font-weight--normal: 300;
$font-weight--bold: 600;
$font-size--body: 17px;

$line-height--title: 1.125;
$line-height--small: 1.15;

$opacity--hidden: 0;
$opacity--semi: 0.85;
$opacity--half: 0.85;
$opacity--light: 0.33;
$opacity--shown: 1;

$white: #a38d6d;
$white--bg: #ffe5b580;
$white--hover: #ffe5b5;
$black: #11110e;
$black--hover: #1f1f1b;
$grey: rgba(255, 255, 255, 0.075);
$grey--hover: rgba(255, 255, 255, 0.15);
$red: #db4c69;
$blue: #3e97e4;
$green: #89df93;

$poe-color__unique: rgb(175, 96, 37);
$poe-color__unique--darker: rgb(53, 13, 13);
$poe-color__red: rgb(255, 0, 0);

$shadow: 0.5rem 0 2rem -0.5rem rgba(0, 0, 0, 0.33);
$shadow-edges--shadow-only: 0 0 40px -5px rgba(0, 0, 0, 0.125);
$shadow-edges: 0 0 40px -5px rgba(0, 0, 0, 0.125);
$shadow-edges--hover: inset 0 0 1px rgba(255, 255, 255, 0.3), 0 0 40px -5px rgba(0, 0, 0, 0.125);

$opacity-start: 0.5;
$opacity-end: 1;

$shortcut-feeds: 4;

$sixteen-nine: math.div(16, 9);
$one-one: math.div(1, 1);
$four-three: math.div(4, 3);
$steam-aspect: math.div(16, 7.5);
$trakt-aspect: math.div(6, 9);

$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1440px;
$breakpoint-xxxlarge: 1800px;
$breakpoint-xxxxlarge: 2400px;

// $cursor: url('icons/icon__cursor.png'), auto !important;

$animation: opacity 0.3s ease-in-out,
  width 0.3s ease-in-out,
  color 0.3s ease-in-out,
  border 0.3s ease-in-out,
  background-color 0.3s ease-in-out,
  transform 0.3s ease-in-out,
  border-radius 0.3s ease-in-out,
  filter 0.3s ease-in-out,
  box-shadow 0.3s ease-in-out,
  padding 0.3s ease-in-out,
  height 0.3s ease-in-out;

$animation-card: box-shadow 0.3s ease-in-out,
  background-color 0.3s ease-in-out;

$animation-long: opacity 1.3s ease-in-out,
  color 1.3s ease-in-out,
  background-color 1.3s ease-in-out,
  filter 1.3s ease-in-out,
  transform 1.3s ease-in-out,
  border-radius 1.3s ease-in-out,
  padding 1.3s ease-in-out,
  height 1.3s ease-in-out;
