.channel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8275rem;
  padding: $span-padding;
  background-color: $grey;
  height: 24px;
  max-width: 75%;
  line-height: 0;
  letter-spacing: -0.0125rem;
  transition: $animation;
  border: 1px solid transparent;
  cursor: pointer;
  min-width: 60px;
  text-align: center;
  font-family: $font-family--title;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-variant: small-caps;

  &:hover,
  &:focus {
    box-shadow: $shadow-edges;
    background-color: rgb(0, 0, 0);
    color: $poe-color__unique;
    border: 1px solid $poe-color__unique;
  }
}

.title {
  font-size: 1.1rem;
  letter-spacing: -0.025em;
  line-height: $line-height--title;
  margin: $title-padding 0;
  @include line-clamp(3);
}

.sectionTitle {
  display: inline-block;
  margin: 0 0 calc($padding / 2);
  padding: calc($padding / 4) $padding;
  box-shadow: $shadow-edges;
  background-color: rgb(0, 0, 0);
  color: $poe-color__unique;
  border: 1px solid $poe-color__unique;
  font-variant: small-caps;
}

.glow {
  opacity: $opacity--hidden;
  transition: $animation;
  background-color: $grey;
  z-index: -1;
  width: 50%;
  height: 50%;
  filter: blur(25px);
  @include center();
  @include border-radius(1000px);
}

.date,
.songDuration {
  position: absolute;
  top: 8%;
  left: 9%;
  font-size: 0.65rem;
  font-weight: $font-weight--bold;
  color: $white;
  background-color: $black;
  border: 1px solid $grey;
  @include box-shadow($shadow-edges--shadow-only);
  padding: calc($padding / 3) calc($padding / 2);
  opacity: $opacity--hidden;
  transition: $animation;
  display: flex;
  align-items: center;
}

.close {
  position: fixed;
  top: calc(8.25% + $padding / 2);
  right: calc(8.5% + $padding / 2);
  z-index: 12;
  width: 60px;
  height: 60px;
  opacity: $opacity--hidden;
  fill: $white;
  padding: calc($padding / 2);
  transition: $animation;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);

  &:hover {
    box-shadow: $shadow-edges;
    background-color: $grey--hover;
  }
}