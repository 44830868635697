@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';


.feed,
.twitch {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  // -webkit-mask-image: linear-gradient(to top, black calc(100% - 0.625rem), transparent 100%);
  // mask-image: linear-gradient(to top, black calc(100% - 0.625rem), transparent 100%);
  width: 100%;
  height: 100%;
  padding: calc($padding / 2) 0;
  @include transition(all 0.333 ease-in-out);

  @include breakpoint('min-width', $breakpoint-medium) {
    padding: 0;
  }

  &.fullscreen {
    align-items: stretch;
  }

  &Offline {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}

.message {
  // font-family: "Hacker", "Hack", monospace;
  // mix-blend-mode: overlay;
  aspect-ratio: $sixteen-nine;

  span {
    @include center();
    font-weight: $font-weight--bold;
  }
}

.disabled {
  opacity: 0.33;
  cursor: not-allowed;
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $padding;
  text-align: left;

  button {
    background-color: $grey;
    padding: $padding;
    display: flex;
    flex-direction: column;
    border: none;
    color: $white;
    border-radius: $border-radius;
    margin: 0 calc($padding / 2);
    transition: $animation;
    min-width: 145px;
    // cursor: pointer;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
      background-color: $grey--hover;
      box-shadow: $shadow-edges;

      span {
        opacity: $opacity--shown;
      }
    }

    h2 {
      margin: 0;
      line-height: 1.125;
      font-size: 1.5rem;
    }

    strong {
      font-weight: $font-weight--bold;
      width: 100%;
    }

    span {
      display: block;
      font-weight: $font-weight--normal;
      font-size: 0.65rem;
      opacity: $opacity--half;
      transition: $animation;
    }
  }

  &Left {
    text-align: left;
  }

  &Right {
    text-align: right;

    strong,
    span {
      text-align: right;
    }
  }
}

.count {
  text-align: center;
  width: 100%;
  padding: $padding;
  padding-top: 0;
  padding-bottom: calc($padding * 2);
  // opacity: $opacity--light;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    font-weight: $font-weight--bold;
  }

  &Inner {
    padding: 0 calc($padding / 2);
  }
}

.loader {
  position: relative;
  width: 72px;
  aspect-ratio: $one-one;
  animation: rotate 1s linear infinite;
  // animate rotation of the loader
  // opacity: 0.75;

  img {
    @include center();
  }
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}