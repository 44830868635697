@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';

.player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: #00000020;
  background-image: url('../../../public/search-alt.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding: calc($padding / 1.5) calc($padding / 1.25);
  overflow: hidden;
  max-width: 172px;
  min-width: 172px;
  height: $nav-height--mobile;
  transition: $animation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  // -webkit-backdrop-filter: blur(25px);
  // backdrop-filter: blur(25px);
  // box-shadow: $shadow-edges;

  @include breakpoint('max-width', $breakpoint-small) {
    position: relative;
    z-index: 12;
  }

  @include breakpoint('min-width', $breakpoint-small) {
    border-radius: $border-radius * 2;
    min-width: 300px;
    // height: $nav-height;
    // padding: calc($padding / 2) $padding;
  }

  &:hover,
  &:focus {
    // background-color: $grey;
    // box-shadow: $shadow-edges--hover;

    .date,
    .play,
    .pause {
      opacity: $opacity--shown;
    }

    .image {
      img {
        opacity: 0.33;
      }
    }
  }

  &Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // overflow: hidden;
    transition: $animation;
  }
}

.image {
  background-color: $grey;
  position: relative;
  height: auto;
  width: 100%;
  aspect-ratio: $one-one;
  overflow: hidden;
  border-radius: calc($padding / 4);
  min-width: 46px;
  max-width: 46px;
  transition: all 0.333s ease-in-out;

  // @include breakpoint('min-width', $breakpoint-small) {
  //   min-width: 48px;
  //   max-width: 48px;
  // }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play,
  .pause {
    height: 32px;
    width: auto;
    max-width: 32px;
    @include center();
    transition: all 0.333s ease-in-out;
    fill: $white;
    opacity: $opacity--hidden;
  }
}

.content {
  width: 100%;
  margin-left: calc($padding / 2);
  overflow: hidden;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &Inner {
    overflow: hidden;
  }
}

.overflow {
  -webkit-mask-image: linear-gradient(to left, black calc(100% - calc($padding / 2)), transparent 100%);
  mask-image: linear-gradient(to left, black calc(100% - calc($padding / 2)), transparent 100%);

  &Inner {
   -webkit-mask-image: linear-gradient(to right, black calc(100% - calc($padding / 2)), transparent 100%);
    mask-image: linear-gradient(to right, black calc(100% - $padding), transparent 100%);
  }
}

.playlist {
  position: absolute;
  top: calc($padding / 1.5);
  left: calc($padding / 1.5);
  z-index: 2;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  // background-color: rgba(0, 0, 0, 0.33);
  background-color: $grey;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: $font-weight--bold;
  font-size: 0.75rem;
  line-height: 0;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  @include box-shadow($shadow-edges);
}

.title {
  width: 100%;
  font-size: 0.725rem;
  line-height: $line-height--small;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  @include line-clamp(1);

  @include breakpoint('min-width', $breakpoint-small) {
    font-size: 0.85rem;
  }
}

.marquee {
  width: fit-content;
  display: flex;
  position: relative;
  opacity: $opacity--shown;
  overflow-x: hidden;
  height: 1rem;
  width: 100%;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  span {
    white-space: nowrap;
    padding: 0;
    height: 1rem;
    font-weight: 900;
    line-height: $line-height--small;
  }
}

.track {
  white-space: nowrap;
}

.description {
  font-size: 0.7rem;
  opacity: 0.7;
  pointer-events: none;

  @include breakpoint('min-width', $breakpoint-small) {
    font-size: 0.9rem;
  }

  p {
    margin: 0;
    @include line-clamp(2);
  }
}

.timeline {
  height: 3px;
  width: 100%;
  background-color: $grey;
  border-radius: calc($border-radius / 2);
  margin-right: calc($padding / 8);

  &Inner {
    height: 100%;
    background-color: $white;
    border-radius: calc($border-radius / 2);
    transition: $animation;
  }
}

// .paused {
//   opacity: 0.5 !important;

//   .marquee {
//     animation-play-state: paused;
//   }
// }

.meta {
  display: flex;
  align-items: center;
  font-size: 0.65rem;
  opacity: 0.75;
  pointer-events: none;
  line-height: 1;
  @include line-clamp(1);

  @include breakpoint('min-width', $breakpoint-small) {
    font-size: 0.8rem;
  }

  span {
    margin-right: $padding;
    line-height: 1;
  }

  img {
    max-width: 20px;
    border-radius: 20px;
    margin-right: calc($padding / 4);
  }
}

.duration {
  display: flex;
  align-items: center;
  margin-top: calc($padding / 4);
  transition: $animation;

  @include breakpoint('min-width', $breakpoint-small) {
    margin-top: calc($padding / 8);
  }
}

.date {
  font-size: 0.6rem;
  font-weight: $font-weight--bold;
  color: $white;
  line-height: 1;
  transition: $animation;
  // border-radius: calc($border-radius / 4);
  // padding: $span-padding;
  text-transform: lowercase;
  // opacity: 0.25;
}

.tags {
  display: flex;
  align-items: center;

  span {
    margin-right: calc($padding / 2);
    text-transform: lowercase;
    font-size: 0.75rem;
    padding: $span-padding;
    line-height: 1;
    border-radius: calc($border-radius / 2);
    background-color: $grey;
    opacity: $opacity--hidden;
    white-space: nowrap;
    text-transform: ellipsis;
    overflow: hidden;
  }
}

.hidden {
  opacity: $opacity--hidden;
  pointer-events: none;
  transition: $animation;
  height: 0;
  width: 0;
  overflow: hidden;
  position: fixed;
  z-index: -1;
  top: -9999px;
  left: -9999px;
}